<template>

    <span class="traplinkedNotificationsWrapper" v-if="traplinkedNotifications">
      <span v-for="traplinkedNotification in traplinkedNotifications" :key="traplinkedNotification.name">
        <traplinked-notification :value="traplinkedNotification" :customer="customer" />
      </span>
    </span>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;
    import TraplinkedNotification from '@/components/customer/TraplinkedNotification';

    export default {
      name: 'TraplinkedNotifications',
      components: {
        TraplinkedNotification
      },
      props: [
        /** customer kann auch ein Objekt mit ID 'ALLE' sein, um für alle Standorte des Keyusers Werte zu setzen */
        'customer'
      ],
      data() {
        return {
          /** mögliche Notifications */
          traplinkedNotifications: null
        }
      },
      watch: {

        customer: function () {
          this.init()
        }

      },
      created() {

        this.init()

      },
      methods: {

        init: function () {

            const url    = process.env.VUE_APP_SERVER_URL + '/notification/listTraplinkedNotifications'
            const params = {
              cardcode:             this.customer ? this.customer.cardcode : null,
              mandant:              this.customer ? this.customer.biotechCountry.name : null,
              biotechUser_id:       this.$store.getters.user.id,
            }

            const that = this
            axios({
              method: 'get',
              params: params,
              url: url
            }).then(response => {

                if (response.data) {
                  that.traplinkedNotifications = response.data
                }

            }).catch(error => {
              console.log(error)
            })

          }

      }

    }

</script>
