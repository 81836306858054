<template>
  <div>

    <div id="content" role="main" class="container">

      <div class="row mt-5">

        <div class="col-md-12">
          <div class="content_title">
            <h1>{{ $t('message.dashboard_h1') }}</h1>
          </div>
        </div>
        <div class="col-md-12" v-if="$store.getters.customer && $store.getters.anzahlCustomer < 2">
          <h2>{{ $store.getters.customer.kunde }}</h2>
          <p class="text-center">
            {{ adresse }}
          </p>
        </div>

      </div>
    </div>

    <!--
      Übersicht für eine Kundenliste eines Key Users,
      nicht anzeigen, wenn der Key User mehr als 10 Standorte hat,
      das sprengt die Datenbank
    -->
    <span v-if="$store.getters.anzahlCustomer > 1 && $store.getters.anzahlCustomer <= 30">

      <div class="row mt-3">
        <div class="col">
          <h3>{{ $t('message.dashboard_h3') }}</h3>
        </div>
      </div>

      <!-- Filter anzeigen mit der Möglichkeit diesen zu löschen -->
      <div class="card mt-5">
        <div class="card-body">
          <filter-charts v-model="filter" />
          <show-filter-charts
              v-on:delete-filter="deleteFilter()"
              :filter="filter"
          />
        </div>
      </div>

      <!-- Übersicht für eine Kundenliste eines Key Users: -->
      <span v-if="$store.getters.user_role.authority == ROLE_KEY_USER">
          <div class="row mt-3">
            <div class="col-md-4">
              <bar-chart-alarme-je-bereiche
                      :key-user="keyuser"
                      v-on:filter-area="setFilterArea"
                      :filter="filter"
              />
            </div>
            <div class="col-md-4">
              <bar-chart-alarme-je-objektkategorie
                      :key-user="keyuser"
                      :filter="filter"
                      v-on:filter-objektkategorie="setFilterObjektkategorie"
              />
            </div>
            <div class="col-md-4">
              <!-- Alarme Jahre -->
              <bar-chart-alarme-je-jahr
                      :key-user="keyuser"
                      :filter="filter"
              />
            </div>
          </div>
        </span>

        <!-- Übersicht für eine Kundenliste eines Sub Users mit mehreren Standorten: -->
        <span v-if="$store.getters.user_role.authority == ROLE_CUSTOMER">
          <div class="row mt-3">
            <div class="col-md-4">
              <bar-chart-alarme-je-bereiche
                      :sub-user="subuserOrCustomer"
                      v-on:filter-area="setFilterArea"
                      :filter="filter"
              />
            </div>
            <div class="col-md-4">
              <bar-chart-alarme-je-objektkategorie
                      :sub-user="subuserOrCustomer"
                      :filter="filter"
                      v-on:filter-objektkategorie="setFilterObjektkategorie"
              />
            </div>
            <div class="col-md-4">
              <!-- Alarme Jahre -->
              <bar-chart-alarme-je-jahr
                      :sub-user="subuserOrCustomer"
                      :filter="filter"
              />
            </div>
          </div>
        </span>

    </span>

    <!-- Übersicht für einen einzelnen Kunden -->
    <span v-if="$store.getters.customer">
      <div class="row mt-5">
        <div class="col" v-if="$store.getters.anzahlCustomer > 1">
          <h3>{{ $t('message.dashboard_uebersicht_standort') }}</h3>
          <h4>{{ adresse }}</h4>
        </div>
        <div class="col" v-else>
          <h3>{{ $t('message.dashboard_uebersicht') }}</h3>
        </div>
      </div>
      <div class="row mt-3" v-if="chartsAvailable">
        <div class="col-md-4">
          <bar-chart-alarme-je-bereiche
                  :customer="$store.getters.customer"
                  v-on:filter-area="setFilterArea"
                  :filter="filter"
          />
        </div>
        <div class="col-md-4">
          <bar-chart-alarme-je-objektkategorie
                  :customer="$store.getters.customer"
                  :filter="filter"
                  v-on:filter-objektkategorie="setFilterObjektkategorie"
          />
        </div>
        <div class="col-md-4">
          <!-- Alarme Jahre -->
          <bar-chart-alarme-je-jahr
                  :customer="$store.getters.customer"
                  :filter="filter"
          />
        </div>
      </div>
      <div v-else>
        <b-alert variant="danger" show class="mt-3 text-center">{{ $t('message.dashboard_no_data') }}</b-alert>
      </div>
    </span>

    <!-- für den KeyUser und SubUser mit mehreren Standorten die Standortliste anzeigen -->
    <div class="standortliste mt-5" v-if="$store.getters.anzahlCustomer > 1">
      <customer-list :show-details="true" :logged-in-user="$store.getters.user" />
    </div>

    <!-- falls Traplinked Fallen vorhanden sind, den Status anzeigen -->
    <div class="traplinkedstatuswrappe mt-5">
      <ShowHideTraplinkedStatus />
    </div>

  </div>
</template>

<script>

  import axios from 'axios'
  axios.defaults.withCredentials = true
  import BarChartAlarmeJeBereiche from '@/components/charts/BarChartAlarmeJeBereich'
  import BarChartAlarmeJeObjektkategorie from '@/components/charts/BarChartAlarmeJeObjektkategorie'
  import BarChartAlarmeJeJahr from "@/components/charts/BarChartAlarmeJeJahr"
  import ShowFilterCharts from "@/components/charts/ShowFilterCharts"
  import FilterCharts from "@/components/charts/FilterCharts"
  import ShowHideTraplinkedStatus from '@/components/traplinkedbericht/ShowHideTraplinkedStatus'

  export default {
    name: 'Dashboard',
    components: {
      BarChartAlarmeJeJahr,
      BarChartAlarmeJeObjektkategorie,
      BarChartAlarmeJeBereiche,
      ShowFilterCharts,
      FilterCharts,
      ShowHideTraplinkedStatus
    },
    data () {
      return {
        /** Filter der Charts */
        filter: {
          /** Filter nach einer Objektkategorie */
          filterObjektkategorie: null,
          /** Filter nach einer Area */
          filterArea: null,
          /** Filter nach Sondereinsatz/Monitoringauftrag */
          auftragsart: {
            monitoringbesuch: 'true',
            einzelauftrag: 'true'
          }
        }
      }
    },
    computed: {

      /** sind Charts des Customers vorhanden */
      chartsAvailable: function () {

        if (
                this.$store.getters.chartAvailableBarChartAlarmeJeBereiche ||
                this.$store.getters.chartAvailableBarChartAlarmeJeObjektkategorie ||
                this.$store.getters.chartAvailableBarChartAlarmeJeJahr
        ) {
          return true;
        }
        return false;

      },
      /** liefert einen eingeloggten KeyUser */
      keyuser: function () {

        if (this.$store.getters.user_role.authority == this.ROLE_KEY_USER) {
            return this.$store.getters.user;
        }
        return null

      },
      /** liefert einen eingeloggten Subuser oder einen Customer */
      subuserOrCustomer: function () {

        if (this.$store.getters.user_role.authority == this.ROLE_CUSTOMER) {
          return this.$store.getters.user;
        }
        return null

      },
      adresse: function () {

        var str = '';
        if (this.$store.getters.customer.zipcode && this.$store.getters.customer.ort) {
          str = this.$store.getters.customer.zipcode + ' ' + this.$store.getters.customer.ort;
        }
        if (str != '') {
          str += ', ';
        }
        if (this.$store.getters.customer.adresse) {
          str += this.$store.getters.customer.adresse
        }
        if (str != '') {
          str += ', ';
        }
        str += this.$t('message.printPositionen_kundennummer') + ' ' + this.$store.getters.customer.cardcode;
        return str;

      }

    },
    mounted: function () {

      this.$store.commit('setChartAvailableBarChartAlarmeJeBereiche', true);
      this.$store.commit('setChartAvailableBarChartAlarmeJeJahr', true);
      this.$store.commit('setChartAvailableBarChartAlarmeJeObjektkategorie', true);

      // Umami Tracking aufrufen
      this.doTracking(this.$route.name);

    },
    methods: {

      /**
       * Filter Objektkategorie löschen
       */
      deleteFilter: function () {
        this.filter = {
          filterObjektkategorie: null,
          filterArea:            null,
          auftragsart: {
            monitoringbesuch: 'true',
            einzelauftrag: 'true'
          }
        }
      },
      /**
       * nach einer Objektkategorie filtern
       */
      setFilterObjektkategorie: function (label) {
        this.filter.filterObjektkategorie = label;
      },
      /**
       * nach einer Area filtern
       */
      setFilterArea: function (label) {
        this.filter.filterArea = label;
      }

    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .circleIcon {
    width: 100px;
    height: 100px;
  }
  .circleLink:hover,
  .circleIcon:hover {
    color: #004731;
    text-decoration: none;
  }
  .circleLink {
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
  }
  h2, .h2 {
    font-size: 1.8rem;
  }
  .card {
    height: 100%;
  }

</style>
