<template>

    <div id="content" role="main">

        <div class="row mt-5">
          <div class="col-md-12">
            <div class="content_title">
              <h1>{{ $t('message.login') }}</h1>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">

              <div>
                <b-alert variant="danger"
                         v-if="loginerror"
                         show>
                  {{ $t('message.login_error') }}
                </b-alert>
                <b-alert variant="success"
                         v-else-if="message"
                         show>
                  {{ message }}
                </b-alert>
              </div>

          </div>
          <div class="col-md-2"></div>
        </div>
        <div class="container" v-if="isServerAvailable === false">

          <b-alert variant="danger" :show="!isServerAvailable">
              {{ $t('message.login_wartung') }}
          </b-alert>

        </div>
        <div class="container mt-5" v-else-if="isServerAvailable === true">
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">

              <div class="card">
                <div class="card-body">

                  <form v-on:submit.prevent="doLogin" name="loginform" id="loginform">
                    <div class="form-group">
                      <label for="username">{{ $t('message.login_username') }}</label>
                      <input type="text"
                             class="form-control"
                             id="username"
                             :placeholder='$t("message.login_username_ph")'
                             required
                             v-model="username">
                    </div>
                    <div class="form-group">
                      <label for="password">{{ $t("message.login_password") }}</label>
                      <input type="password"
                             class="form-control"
                             id="password"
                             :placeholder='$t("message.login_password_ph")'
                             required
                             v-model="password">
                    </div>
                    <button type="submit" :disabled="!formvalid" class="btn btn-outline-dark">
                      <span class="oi oi-account-login"></span>&nbsp;{{ $t("message.login") }}
                    </button>
                    <hr />
                    <router-link to="/forgotPassword"
                                 href="#">
                      [ {{ $t("message.login_password_vergessen") }} ]
                    </router-link>
                  </form>

                </div>
              </div>

            </div>
            <div class="col-md-2"></div>
          </div>
        </div>

    </div>

</template>

<script>

  import axios from 'axios'
  axios.defaults.withCredentials = true

  export default {
    name: 'Login',
    props: {
      /** eine Erfolgsmeldung */
      message: {
        type: String,
        required: false
      }
    },
    data () {
      return {
        username: null,
        password: null,
        /** wenn true dann wird eine Fehlermeldung angezeigt */
        loginerror: false,
        /** anzeigen ob BiotechAnalytics Server erreichbar ist */
        isServerAvailable: null,
      }
    },
    created () {

      this.checkServer()

      // Umami Tracking aufrufen
      this.doTracking(this.$route.name)

      // loggedInAs auf null setzen:
      this.$store.commit('setLoggedInAs', null)

    },
    computed: {

      formvalid: function() {

        if (this.username && this.username.length > 2 && this.password && this.password.length > 2) return true
        return false

      }

    },
    methods: {

      /** prüfe ob Server erreichbar ist */
      checkServer: function () {

        // hole aktuelle Version zur Prüfung ob Server erreichbar
        var url = process.env.VUE_APP_SERVER_URL + '/application'
        var that = this;
        axios.get(url).then(function (response) {

          console.log('Server erreichbar: ', response)
          if (response && response.data && response.data.version != null) {
            that.isServerAvailable = true
          } else {
            that.isServerAvailable = false
            setTimeout(function(){ that.checkServer() }, 30000)
          }

        }).catch(function (error) {
          // handle error
          console.log(error)
          that.isServerAvailable = false
          setTimeout(function(){ that.checkServer() }, 30000)
        })

      },
      doLogin: function () {

        console.log('doLogin ...')

        // Daten im Cache mal alle zurücksetzen:
        this.$store.commit('setLoggedInAs', null)
        this.$store.commit('setStandorte', null)
        this.$store.commit('setCustomer', null)
        this.$store.commit('setAnzahlCustomer', null)

        this.loginerror = false
        this.showSpinner()
        let url = process.env.VUE_APP_SERVER_URL + '/login/auth'
        const that  = this
        let params  = {
          username: this.username,
          password: this.password,
          lang: this.$i18n.locale
        }
        axios({
          method: 'POST',
          data: params,
          url: url,
        }).then(response => {

          if (response.data.error) {
            console.log('Login Error: ', response.data.error)
            that.loginerror = true
            that.hideSpinner()
          } else {

            that.$store.commit('setUserRole', response.data.role)
            that.$store.commit('setUser',     response.data.user)

            // ein normaler User geht direkt in den Besuchsbericht seiner Firma:
            if (response.data.role && response.data.role.authority == that.ROLE_CUSTOMER) {

              // auch ein normaler User kann verschiedene Kunden/Verträge haben:
              url     = process.env.VUE_APP_SERVER_URL + '/biotechUser/countCustomerOfBiotechUser';
              params  = {
                biotechUser_id: response.data.user.id
              }
              axios({
                method: 'post',
                params: params,
                url: url
              }).then(response2 => {
                that.$store.commit('setAnzahlCustomer', response2.data.anzahl)
                if (response2.data.anzahl == 1) {
                  that.setCustomerAndUserRights(response2.data.customer[0])
                  that.$router.push('/Besuchsbericht')
                } else {
                  that.setCustomerAndUserRights(null)
                  that.$router.push('/Dashboard')
                }
                that.hideSpinner()
              }).catch(error => {
                that.hideSpinner()
                console.log('Fehler bei countCustomerOfBiotechUser: ', error)
                that.loginerror = true
              })

            } else if (response.data.role && response.data.role.authority == that.ROLE_KEY_USER) {

              let query = null
              if (
                  that.$store.getters.routeName &&
                  that.$store.getters.routeName != 'Welcome' &&
                  that.$store.getters.routeName != 'Login' &&
                  that.$store.getters.routeQuery &&
                  that.$store.getters.routeQuery.cardcode &&
                  that.$store.getters.routeQuery.mandant
              ) {
                query = that.$store.getters.routeQuery
              }
              // ein Key User zur Kundenliste falls er mehrere Kunden hat, sonst direkt ins Dashboard
              url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/countCustomerOfKeyUser'
              params  = {
                userjson: JSON.stringify(response.data.user),
                routeQuery: query ? JSON.stringify(query) : null
              };
              axios({
                method: 'post',
                params: params,
                url: url
              }).then(response2 => {

                that.$store.commit('setAnzahlCustomer', response2.data.anzahl)
                if (response2.data.anzahl == 1) {

                  that.setCustomerAndUserRights(response2.data.customer)
                  if (
                      that.$store.getters.routeName
                      && that.$store.getters.routeName != 'Welcome'
                      && that.$store.getters.routeName != 'Login'
                      && that.$store.getters.routeName != 'ChangePassword'
                      && that.$store.getters.routeName != 'ForgotPassword'
                  ) {
                    // es wurde per GET Parameter ein Ziel übergeben:
                    console.log('per GET übermittelte route: ' + that.$store.getters.routeName)
                    that.$router.push(that.$store.getters.routeName)
                  } else {
                    that.$router.push('/Besuchsbericht')
                  }
                  that.hideSpinner()

                } else {

                  if (
                      query &&
                      response2.data.customer
                  ) {

                    // es wurde per GET Parameter ein Ziel und ein Kunde übergeben:
                    that.setCustomerAndUserRights(response2.data.customer)
                    that.$router.push(that.$store.getters.routeName)

                  } else {

                    that.setCustomerAndUserRights(null)
                    that.$router.push('/Dashboard')

                  }
                  that.hideSpinner()

                }

              }).catch( error => {
                that.hideSpinner()
                console.log('Fehler bei countCustomerOfKeyUser: ' + error)
                that.loginerror = true
              })

            } else if (response.data.role && response.data.role.authority == that.ROLE_ADMIN) {

              console.log('login Admin ... routeName = ' + that.$store.getters.routeName)
              if (
                  that.$store.getters.routeName &&
                  that.$store.getters.routeQuery &&
                  that.$store.getters.routeName != 'Welcome' &&
                  that.$store.getters.routeName != 'Login' &&
                  that.$store.getters.routeQuery.cardcode &&
                  that.$store.getters.routeQuery.mandant
              ) {

                // route direkt zum evt. übergebenen Kunden und Page:
                let query = that.$store.getters.routeQuery
                if (query.cardcode && query.mandant) {

                  url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getCustomer'
                  params  = {
                    mandant:  query.mandant,
                    cardcode: query.cardcode
                  }
                  axios({
                    method: 'post',
                    params: params,
                    url: url
                  }).then(function (response2) {
                    that.setCustomerAndUserRights(response2.data)
                    that.$router.push(that.$store.getters.routeName)
                    that.hideSpinner()
                  })

                }

              } else {
                // ein Admin zur Kundenliste
                that.$router.push('/Kundenliste')
                that.hideSpinner()
              }
            }

          }

        }).catch(error => {
          that.hideSpinner()
          console.log(error)
          that.loginerror = true
        })

      }

    }
  }

</script>
