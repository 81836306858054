<template>

    <div class="benutzer-modal-wrapper">

      <b-modal :id="modalId ? modalId : 'modal-edit'"
               :title="benutzer && benutzer.id != -1 ? 'Benutzer ändern' : 'Benutzer hinzufügen'"
               size="xl">

        <b-form @submit="onSubmit" v-if="benutzer">

          <b-form-group id="input-group-username"
                        label="Email Addresse:"
                        label-for="username">

            <b-form-input id="username"
                          v-model="benutzer.username"
                          type="email"
                          ref="username"
                          required
                          :state="usernameValidation || usernameUnique"
                          :placeholder="$t('message.benutzer_benutzername')"
            />

            <b-form-invalid-feedback :state="usernameValidation">
              {{ $t('message.benutzer_valid_email') }}
            </b-form-invalid-feedback>

          </b-form-group>
          <b-form-group id="input-group-vorname"
                        label="Vorname:"
                        label-for="vorname">

            <b-form-input id="vorname"
                          v-model="benutzer.vorname"
                          type="text"
                          :placeholder="$t('message.benutzer_vorname')"
            />

          </b-form-group>
          <b-form-group id="input-group-nachname"
                        label="Nachname:"
                        label-for="nachname">

            <b-form-input id="nachname"
                          v-model="benutzer.nachname"
                          type="text"
                          :placeholder="$t('message.benutzer_nachname')"
            />

          </b-form-group>
          <b-form-checkbox v-if="benutzer && benutzer.id != -1 && !usernameNotUniqueAllowed"
                           id="checkbox-enabled"
                           v-model="benutzer.enabled"
                           name="checkbox-enabled"
                           value="true"
                           unchecked-value="false">
            {{ $t('message.benutzer_aktiv') }}
          </b-form-checkbox>

          <!-- auch nur einen Standort anzeigen um die Rechte setzen zu können -->
          <span v-if="$store.getters.anzahlCustomer && $store.getters.anzahlCustomer > 0">
              <hr />

              <h6 class="text-left">{{ $t('message.benutzer_standorte') }}</h6>
              <!-- Standortliste -->
              <customer-list :show-details="false"
                             :number-per-page="20"
                             :benutzer="benutzer"
                             :action="'SELECT-VERTRAEGE'"
                             :logged-in-user="$store.getters.user"
                             v-on:selected-customers="setSelectedCustomers"
              />
          </span>

        </b-form>

        <template v-slot:modal-footer="{ hide }">

          <b-button size="sm" variant="warning" @click="hide()">
            {{ $t('message.default_abbrechen') }}
          </b-button>
          <b-button variant="success" @click="onSubmit()" :disabled="!usernameValidation">
            <b-icon icon="check" />&nbsp;{{ $t('message.default_speichern') }}
          </b-button>
        </template>

      </b-modal>

    </div>

</template>

<script>

    import axios from 'axios'
    import bootbox from 'bootbox';
    axios.defaults.withCredentials = true

    export default {
      name: 'BenutzerModal',
      props: [
          /** der Benutzer der geändert werden soll */
          'value',
          'modal-id',
      ],
      data () {
        return {
          benutzer: null,
          usernameUnique: true,
          /** erlaube den Benutzer, auch wenn er schon existiert */
          usernameNotUniqueAllowed: false,
          /** Kunden die dem Benutzer zugewiesen wurden */
          selectedCustomers: null,
        }
      },
      watch: {
        value: function () {
          this.init()
        }
      },
      mounted () {
        this.init()
      },
      computed: {

        /** prüfe oder Benutzername bereits verwendet wird und ob genug Zeichen eingegeben wurden */
        usernameValidation: function() {

          if (this.benutzer) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(this.benutzer.username)
          }
          return false

        },

      },
      methods: {

        init: function () {

          if (this.value) {
            this.benutzer = this.value
          } else {
            this.benutzer = null
          }

        },
        /**
         * für den Benutzer ausgewählte Kunden
         */
        setSelectedCustomers: function (obj) {

          console.log('set Selected customers Benutzer Modal ...', obj)
          this.selectedCustomers = obj.selectedCustomers
          this.$emit('set-selected-customers', obj)

        },
        /**
         * der Benutzername ist nicht unique,
         * frage den Benutzer ob er den User trotzdem verwenden will
         */
        usernameNotUnique: function () {

          const that = this
          this.usernameUnique = false
          this.$refs.username.$el.focus()
          const text = this.$t('message.benutzer_exists').replace('{0}', this.benutzer.username)
          bootbox.confirm(text, function(result) {
            if (result) {

              that.usernameNotUniqueAllowed = true
              that.onSubmit()

            }
          })

        },
        doSubmit: function () {

          console.log('submit Form ...')
          this.showSpinner()
          try {
            // prüfe zuerst ob der Username unique ist
            if (this.benutzer) {

              const that   = this
              let url      = process.env.VUE_APP_SERVER_URL + '/biotechUser/isUsernameUnique'
              let params   = {
                username:                 this.benutzer.username,
                id:                       this.benutzer.id,
                usernameNotUniqueAllowed: this.usernameNotUniqueAllowed,
              }
              axios ({
                method:   'POST',
                params:   params,
                url:      url
              }).then(response => {

                console.log('Username is unique = ' + response.data.unique)
                if (response.data.unique) {

                  // Benutzername ist unique!
                  that.usernameUnique = true

                  let customers = []
                  if (that.selectedCustomers) {
                    for (let i = 0; i < that.selectedCustomers.length; i++) {
                      customers.push ({
                        mandant:        that.selectedCustomers[i].biotechCountry.name,
                        cardcode:       that.selectedCustomers[i].cardcode,
                        benutzerrechte: that.selectedCustomers[i].benutzerrechte,
                      })
                    }
                  }
                  console.log('save customers', customers)

                  // enabled ist ein String, noch zu boolean ändern:
                  if (that.benutzer.enabled == 'false')     that.benutzer.enabled = false
                  else if (that.benutzer.enabled == 'true') that.benutzer.enabled = true

                  url    = process.env.VUE_APP_SERVER_URL + '/biotechUser/update'
                  params = {
                    user:                     that.benutzer,
                    customer:                 customers,
                    usernameNotUniqueAllowed: that.usernameNotUniqueAllowed
                  }
                  axios.post (
                      url,
                      params,
                      {
                        headers: { 'Content-Type': 'text/plain' }
                      }
                  ).then(response => {

                    if (response.data.id) {
                      that.$emit('benutzer-added', response.data)
                      that.$bvModal.hide(that.modalId ? that.modalId : 'modal-edit')
                    }
                    that.hideSpinner()

                  })

                } else {

                  that.hideSpinner()
                  that.usernameNotUnique()

                }

              })

            }
          } catch (e) {
            console.log('Fehler? ', e)
          }

        },
        /** Benutzer Änderung speichern */
        onSubmit: function () {

          // prüfe ob Standorte ausgewählt wurden und gib Warnung aus, falls nicht
          if (!this.selectedCustomers || this.selectedCustomers.length < 1) {

            const that = this
            const text = this.$t('message.benutzerModel_keinStandort')
            bootbox.confirm(text, result => {
              if (result) {
                that.doSubmit()
              }
            })

          } else {
            this.doSubmit()
          }

        },

      }

    }

</script>
