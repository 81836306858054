<template>

    <span :class="theClass"
          v-if="customer">
      <div class="row">
        <div class="col-md-6">
            <b-form-checkbox
                v-if="customer.cardcode != 'ALLE'"
                :id="'checkbox-' + customer.id"
                v-model="isChecked"
                name="isChecked"
                value="true"
                unchecked-value="false">
                {{ label }}
            </b-form-checkbox>
            <div v-else>
              <b-button-group size="sm" :id="'checkbox-' + customer.id">
                <b-button :variant="isChecked ? 'success' : 'outline-success'"
                          v-b-tooltip.hover.left
                          :title="$t('message.customerNotifications_fuer_alle_aktivieren')"
                          @click="isChecked = true">
                  <b-icon icon="check-circle"></b-icon>
                </b-button>
                <b-button variant="outline-danger"
                          v-b-tooltip.hover.right
                          :title="$t('message.customerNotifications_fuer_alle_deaktivieren')"
                          @click="isChecked = false">
                  <b-icon icon="dash-circle"></b-icon>
                </b-button>
              </b-button-group>
              &nbsp;<label :for="'checkbox-' + customer.id">{{ label }}</label>
            </div>
        </div>
        <div class="col-md-6">
            <b-form-checkbox
                v-if="customer.cardcode != 'ALLE'"
                :id="'checkbox-app-' + customer.id"
                v-model="isCheckedApp"
                name="isCheckedApp"
                value="true"
                unchecked-value="false">
                {{ labelApp }}
            </b-form-checkbox>
            <div v-else>
              <b-button-group size="sm" :id="'checkbox-app-' + customer.id">
                <b-button :variant="isCheckedApp ? 'success' : 'outline-success'"
                          v-b-tooltip.hover.left
                          :title="$t('message.customerNotifications_fuer_alle_aktivieren')"
                          @click="isCheckedApp = true">
                  <b-icon icon="check-circle"></b-icon>
                </b-button>
                <b-button variant="outline-danger"
                          v-b-tooltip.hover.right
                          :title="$t('message.customerNotifications_fuer_alle_deaktivieren')"
                          @click="isCheckedApp = false">
                  <b-icon icon="dash-circle"></b-icon>
                </b-button>
              </b-button-group>
              &nbsp;<label :for="'checkbox-app-' + customer.id">{{ labelApp }}</label>
            </div>
        </div>
      </div>
    </span>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;

    export default {
      name: 'DisableNotificationNeuerBesuchsbericht',
      props: [
          /** customer kann auch ein Objekt mit ID 'ALLE' sein, um für alle Standorte des Keyusers Werte zu setzen */
          'customer',
          'label',
          'labelApp',
      ],
      data () {
        return {
          isChecked: null,
          isCheckedApp: null,
        }
      },
      watch: {

        customer: function () {
          this.init()
        },
        isChecked: function (newVal, oldVal) {
          console.log('newVal = ' + newVal + ' oldVal = ' + oldVal)
          if (newVal != null && oldVal != null && newVal != oldVal) {
            this.change(newVal)
          }
        },
        isCheckedApp: function (newVal, oldVal) {
          console.log('newVal = ' + newVal + ' oldVal = ' + oldVal)
          if (newVal != null && oldVal != null && newVal != oldVal) {
            this.change(null, newVal)
          }
        },
        '$store.state.allEmailNotificationInactive': function () {
          console.log('allEmailNotificationInactive', this.$store.state.allEmailNotificationInactive)
          this.init()
        },
        '$store.state.allEmailNotificationActive': function () {
          console.log('allEmailNotificationActive', this.$store.state.allEmailNotificationActive)
          this.init()
        },
        '$store.state.allAppNotificationInactive': function () {
          console.log('allAppNotificationInactive', this.$store.state.allAppNotificationInactive)
          this.init()
        },
        '$store.state.allAppNotificationActive': function () {
          console.log('allAppNotificationActive', this.$store.state.allAppNotificationActive)
          this.init()
        }

      },
      computed: {

        theClass: function () {

          if (this.label) {
            return 'disableNotificationNeuerBesuchsberichtLabel'
          }
          return 'disableNotificationNeuerBesuchsbericht'

        }

      },
      created () {

        this.init()

      },
      methods: {

        change: function (newVal, newValApp) {

          console.log('isChecked geändert auf ' + newVal + ' ' + newValApp)

            this.showSpinner()
            // Ausnahme setzen:
            const url     = process.env.VUE_APP_SERVER_URL + '/notification/save'
            const params  = {
              cardcode:                      this.customer.cardcode,
              mandant:                       this.customer.biotechCountry.name,
              biotechUser_id:                this.$store.getters.user.id,
            }

            if (newVal != null) {
              params.disableNeuerBesuchsbericht = this.customer.cardcode == 'ALLE' ? newVal : this.isChecked
            } else if (newValApp != null) {
              params.disableNeuerBesuchsberichtApp = this.customer.cardcode == 'ALLE' ? newValApp : this.isCheckedApp
            }

            const that = this
            axios({
              method: 'GET',
              params: params,
              url: url,
            }).then(function () {

              console.log('Notififation Ausnahme gespeichert.')
              if (that.customer.cardcode == 'ALLE') {

                let title = that.$t('message.customerNotifications_fuer_alle_aktivieren')
                let text = that.$t('message.customerNotifications_fuer_alle_aktivieren_ok')
                if (newVal === false || newValApp === false) {
                  title = that.$t('message.customerNotifications_fuer_alle_deaktivieren')
                  text = that.$t('message.customerNotifications_fuer_alle_deaktivieren_ok')
                }
                that.$bvToast.toast(text, {
                  title: title,
                  variant: 'success',
                  autoHideDelay: 3000,
                })

              }
              that.hideSpinner()

            }).catch(error => {
              console.log(error)
              that.$bvToast.toast(that.$t('message.customerNotifications_fuer_alle_deaktivieren_fail'), {
                title: 'Error',
                variant: 'success',
                autoHideDelay: 5000
              })
              that.hideSpinner()
            })

        },
        init: function () {

          if (this.customer) {

            const url     = process.env.VUE_APP_SERVER_URL + '/notification/show'
            const params  = {
              cardcode:         this.customer.cardcode,
              mandant:          this.customer.biotechCountry.name,
              biotechUser_id:   this.$store.getters.user.id,
            }

            const that = this
            axios({
              method: 'GET',
              params: params,
              url: url,
            }).then(function (response) {

                if (response.data.id) {
                  console.log('disableNeuerBesuchsbericht = ' + response.data.disableNeuerBesuchsbericht)
                  that.isChecked = response.data.disableNeuerBesuchsbericht ? false : true
                  that.isCheckedApp = response.data.disableNeuerBesuchsberichtApp ? false : true
                } else {
                  console.log('keine Antwort, isChecked = true')
                  that.isChecked = true
                  that.isCheckedApp = true
                }

            }).catch(error => {
              console.log(error)
            })

          }

        }

      }

    }

</script>