<template>

    <span class="notificationAlarm">
      <div class="row">
        <div class="col-md-6">
          <b-form-checkbox
                v-if="customer.cardcode != 'ALLE'"
                :id="'checkbox-notificationAlarmGelb-' + (customer ? customer.id : '')"
                v-model="isGelbChecked"
                name="isGelbChecked"
                value="true"
                unchecked-value="false">
            {{ $t('message.notificationAlarm_gelber_alarm') }}
          </b-form-checkbox>
          <div v-else>
            <b-button-group size="sm" :id="'checkbox-notificationAlarmGelb-' + (customer ? customer.id : '')">
              <b-button :variant="isGelbChecked ? 'success' : 'outline-success'"
                        v-b-tooltip.hover.left
                        :title="$t('message.customerNotifications_fuer_alle_aktivieren')"
                        @click="isGelbChecked = true">
                <b-icon icon="check-circle"></b-icon>
              </b-button>
              <b-button variant="outline-danger"
                        v-b-tooltip.hover.right
                        :title="$t('message.customerNotifications_fuer_alle_deaktivieren')"
                        @click="isGelbChecked = false">
                <b-icon icon="dash-circle"></b-icon>
              </b-button>
            </b-button-group>
            &nbsp;<label :for="'checkbox-notificationAlarmGelb-' + (customer ? customer.id : '')">
              {{ $t('message.notificationAlarm_gelber_alarm') }}
            </label>
          </div>
        </div>
        <div class="col-md-6">
          <b-form-checkbox
              v-if="customer.cardcode != 'ALLE'"
              :id="'checkbox-notificationAlarmGelb-app-' + (customer ? customer.id : '')"
              v-model="isGelbCheckedApp"
              name="isGelbCheckedApp"
              value="true"
              unchecked-value="false">
              {{ $t('message.notificationAlarm_gelber_alarm_app') }}
          </b-form-checkbox>
          <div v-else>
            <b-button-group size="sm" :id="'checkbox-notificationAlarmGelb-app-' + (customer ? customer.id : '')">
              <b-button :variant="isGelbCheckedApp ? 'success' : 'outline-success'"
                        v-b-tooltip.hover.left
                        :title="$t('message.customerNotifications_fuer_alle_aktivieren')"
                        @click="isGelbCheckedApp = true">
                <b-icon icon="check-circle"></b-icon>
              </b-button>
              <b-button variant="outline-danger"
                        v-b-tooltip.hover.right
                        :title="$t('message.customerNotifications_fuer_alle_deaktivieren')"
                        @click="isGelbCheckedApp = false">
                <b-icon icon="dash-circle"></b-icon>
              </b-button>
            </b-button-group>
            &nbsp;<label :for="'checkbox-notificationAlarmGelb-app-' + (customer ? customer.id : '')">
              {{ $t('message.notificationAlarm_gelber_alarm_app') }}
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-form-checkbox
              v-if="customer.cardcode != 'ALLE'"
              :id="'checkbox-notificationAlarmRot-' + (customer ? customer.id : '')"
              v-model="isRotChecked"
              name="isRotChecked"
              value="true"
              unchecked-value="false">
              {{ $t('message.notificationAlarm_roter_alarm') }}
          </b-form-checkbox>
          <div v-else>
            <b-button-group size="sm" :id="'checkbox-notificationAlarmRot-' + (customer ? customer.id : '')">
              <b-button :variant="isRotChecked ? 'success' : 'outline-success'"
                        v-b-tooltip.hover.left
                        :title="$t('message.customerNotifications_fuer_alle_aktivieren')"
                        @click="isRotChecked = true">
                <b-icon icon="check-circle"></b-icon>
              </b-button>
              <b-button variant="outline-danger"
                        v-b-tooltip.hover.right
                        :title="$t('message.customerNotifications_fuer_alle_deaktivieren')"
                        @click="isRotChecked = false">
                <b-icon icon="dash-circle"></b-icon>
              </b-button>
            </b-button-group>
            &nbsp;<label :for="'checkbox-notificationAlarmRot-' + (customer ? customer.id : '')">
              {{ $t('message.notificationNeuerMangel') }}
            </label>
          </div>
        </div>
        <div class="col-md-6">
          <b-form-checkbox
              v-if="customer.cardcode != 'ALLE'"
              :id="'checkbox-notificationAlarmRot-app-' + (customer ? customer.id : '')"
              v-model="isRotCheckedApp"
              name="isRotCheckedApp"
              value="true"
              unchecked-value="false">
              {{ $t('message.notificationAlarm_roter_alarm_app') }}
          </b-form-checkbox>
          <div v-else>
            <b-button-group size="sm" :id="'checkbox-notificationAlarmRot-app-' + (customer ? customer.id : '')">
              <b-button :variant="isRotCheckedApp ? 'success' : 'outline-success'"
                        v-b-tooltip.hover.left
                        :title="$t('message.customerNotifications_fuer_alle_aktivieren')"
                        @click="isRotCheckedApp = true">
                <b-icon icon="check-circle"></b-icon>
              </b-button>
              <b-button variant="outline-danger"
                        v-b-tooltip.hover.right
                        :title="$t('message.customerNotifications_fuer_alle_deaktivieren')"
                        @click="isRotCheckedApp = false">
                <b-icon icon="dash-circle"></b-icon>
              </b-button>
            </b-button-group>
            &nbsp;<label :for="'checkbox-notificationAlarmRot-app-' + (customer ? customer.id : '')">
              {{ $t('message.notificationAlarm_roter_alarm_app') }}
            </label>
          </div>
        </div>
      </div>
    </span>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;

    export default {
      name: 'NotificationAlarm',
      props: [
        /** customer kann auch ein Objekt mit ID 'ALLE' sein, um für alle Standorte des Keyusers Werte zu setzen */
        'customer'
      ],
      data () {
        return {
          isGelbChecked: null,
          isGelbCheckedApp: null,
          isRotChecked: null,
          isRotCheckedApp: null,
        }
      },
      watch: {

        customer: function () {
          this.init()
        },
        isGelbChecked: function (newVal, oldVal) {
          console.log('newVal = ' + newVal + ' oldVal = ' + oldVal)
          if (newVal != null && oldVal != null && newVal != oldVal) {
            this.changeGelb(newVal)
          }
        },
        isGelbCheckedApp: function (newVal, oldVal) {
          console.log('newVal = ' + newVal + ' oldVal = ' + oldVal)
          if (newVal != null && oldVal != null && newVal != oldVal) {
            this.changeGelb(null, newVal)
          }
        },
        isRotChecked: function (newVal, oldVal) {
          console.log('newVal = ' + newVal + ' oldVal = ' + oldVal)
          if (newVal != null && oldVal != null && newVal != oldVal) {
            this.changeRot(newVal)
          }
        },
        isRotCheckedApp: function (newVal, oldVal) {
          console.log('newVal = ' + newVal + ' oldVal = ' + oldVal)
          if (newVal != null && oldVal != null && newVal != oldVal) {
            this.changeRot(null, newVal)
          }
        },
        '$store.state.allEmailNotificationInactive': function () {
          console.log('allEmailNotificationInactive', this.$store.state.allEmailNotificationInactive)
          this.init()
        },
        '$store.state.allEmailNotificationActive': function () {
          console.log('allEmailNotificationActive', this.$store.state.allEmailNotificationActive)
          this.init()
        },
        '$store.state.allAppNotificationInactive': function () {
          console.log('allAppNotificationInactive', this.$store.state.allAppNotificationInactive)
          this.init()
        },
        '$store.state.allAppNotificationActive': function () {
          console.log('allAppNotificationActive', this.$store.state.allAppNotificationActive)
          this.init()
        }

      },
      created () {

        this.init()

      },
      methods: {

        changeGelb: function (newVal, newValApp) {

          this.showSpinner()
          console.log('changeGelb geändert auf ' + newVal + ' ' + newValApp)

          // Ausnahme setzen:
          const url     = process.env.VUE_APP_SERVER_URL + '/notification/save'
          const params  = {
            cardcode:             this.customer ? this.customer.cardcode : null,
            mandant:              this.customer ? this.customer.biotechCountry.name : null,
            biotechUser_id:       this.$store.getters.user.id,
          }

          if (newVal != null) {
            params.gelberAlarm = this.customer.cardcode == 'ALLE' ? newVal : this.isGelbChecked
          } else if (newValApp != null) {
            params.gelberAlarmApp = this.customer.cardcode == 'ALLE' ? newValApp : this.isGelbCheckedApp
          }

          const that = this
          axios({
            method: 'GET',
            params: params,
            url: url,
          }).then(function () {

            console.log('isGelbChecked gespeichert.')
            if (that.customer.cardcode == 'ALLE') {

              let title = that.$t('message.customerNotifications_fuer_alle_aktivieren')
              let text = that.$t('message.customerNotifications_fuer_alle_aktivieren_ok')
              if (newVal === false || newValApp === false) {
                title = that.$t('message.customerNotifications_fuer_alle_deaktivieren')
                text = that.$t('message.customerNotifications_fuer_alle_deaktivieren_ok')
              }
              that.$bvToast.toast(text, {
                title: title,
                variant: 'success',
                autoHideDelay: 3000,
              })

            }
            that.hideSpinner()

          }).catch(error => {
            console.log(error)
            that.$bvToast.toast(that.$t('message.customerNotifications_fuer_alle_deaktivieren_fail'), {
              title: 'Error',
              variant: 'success',
              autoHideDelay: 5000
            })
            that.hideSpinner()
          })

        },
        changeRot: function (newVal, newValApp) {

          this.showSpinner()
          console.log('changeRot geändert auf ' + newVal + ' ' + newValApp)

          // Ausnahme setzen:
          const url     = process.env.VUE_APP_SERVER_URL + '/notification/save'
          const params  = {
            cardcode:             this.customer ? this.customer.cardcode : null,
            mandant:              this.customer ? this.customer.biotechCountry.name : null,
            biotechUser_id:       this.$store.getters.user.id,
          }

          if (newVal != null) {
            params.roterAlarm = this.customer.cardcode == 'ALLE' ? newVal : this.isRotChecked
          } else if (newValApp != null) {
            params.roterAlarmApp = this.customer.cardcode == 'ALLE' ? newValApp : this.isRotCheckedApp
          }

          const that = this
          axios({
            method: 'GET',
            params: params,
            url: url,
          }).then(function () {

            console.log('isRotChecked gespeichert.')
            if (that.customer.cardcode == 'ALLE') {

              let title = that.$t('message.customerNotifications_fuer_alle_aktivieren')
              let text = that.$t('message.customerNotifications_fuer_alle_aktivieren_ok')
              if (newVal === false || newValApp === false) {
                title = that.$t('message.customerNotifications_fuer_alle_deaktivieren')
                text = that.$t('message.customerNotifications_fuer_alle_deaktivieren_ok')
              }
              that.$bvToast.toast(text, {
                title: title,
                variant: 'success',
                autoHideDelay: 3000,
              })

            }
            that.hideSpinner()

          }).catch(error => {
            console.log(error)
            that.$bvToast.toast(that.$t('message.customerNotifications_fuer_alle_deaktivieren_fail'), {
              title: 'Error',
              variant: 'success',
              autoHideDelay: 5000
            })
            that.hideSpinner()
          })

        },
        init: function () {

            const url     = process.env.VUE_APP_SERVER_URL + '/notification/show'
            const params  = {
              cardcode:             this.customer ? this.customer.cardcode : null,
              mandant:              this.customer ? this.customer.biotechCountry.name : null,
              biotechUser_id:       this.$store.getters.user.id,
            }

            const that = this
            axios({
              method: 'get',
              params: params,
              url: url,
            }).then(function (response) {

                if (response.data.id) {
                  console.log('disableNeuerBesuchsbericht = ' + response.data.disableNeuerBesuchsbericht)
                  that.isRotChecked     = response.data.roterAlarm     ? true : false
                  that.isRotCheckedApp  = response.data.roterAlarmApp  ? true : false
                  that.isGelbChecked    = response.data.gelberAlarm    ? true : false
                  that.isGelbCheckedApp = response.data.gelberAlarmApp ? true : false
                } else {
                  console.log('keine Antwort, isChecked = false')
                  that.isRotChecked     = false
                  that.isRotCheckedApp  = false
                  that.isGelbChecked    = false
                  that.isGelbCheckedApp = false
                }

            }).catch(error => {
              console.log(error)
            })

          }

      }

    }

</script>
